import { postJSON } from 'api/fetch';
import { pollBatchStatus, pollWorkerStatus } from 'api/workerStatus';
import { fromJS } from 'immutable';
import { extend, startsWith } from 'lodash';

import * as hiringActions from 'actions/hiring';
import * as meteringActions from 'actions/metering';
import * as modalsActions from 'actions/modals';
import {
  approveAvailabilityAlert,
  resolveClockOutApprovalAlert,
} from 'actions/notifications';
import {
  toggleEmptyScheduleDrawer,
  togglePrintDrawer,
  togglePublishDrawer,
  toggleTemplatesDrawer,
} from 'actions/scheduleBuilder';
import { createMonetizationCtaEvent } from 'actions/session';
import * as settingsActions from 'actions/settings';
import { openMonetizationUpsellExportModal } from 'actions/timesheets';

import * as hiringSelectors from 'selectors/hiring';
import * as meteringSelectors from 'selectors/metering';
import * as sessionSelectors from 'selectors/session';

import {
  changeToBasicTier,
  changeToEssentialsTier,
  changeToNewTier,
  changeToPlusTier,
} from 'features/biller/slice';
import {
  buildTeamAppTeamCalendlyUrl,
  calendlyUrlBuilderCommand,
} from 'features/calendlyEnterprise';
import {
  createChannel,
  toggleMessengerShown,
} from 'features/messengerv2/actions';
import { VIEWS } from 'features/messengerv2/constants';
import { setCurrentChatView } from 'features/messengerv2/slice';
import { FULL_SCREEN_PATHS } from 'features/payroll/constants';
import { trackPTORequestEvents } from 'features/ptoPolicy/tracking';
import { showQ2TierChangeModal } from 'features/q2/modals';
import { trackTeamAvailabilityUxEvents } from 'features/scheduleBuilder/tracking';
import { storeParams } from 'features/signUp/util/referralParams';

import getIsEmbedded from 'util/embedded';
import GoogleMaps from 'util/googleMaps';
import { printContent } from 'util/printContent';
import { browserHistory } from 'util/router';
import {
  facebookTrackEvent,
  getOriginContext,
  setOriginContext,
  trackBingActivation,
  trackGoogleConversionActivation,
  trackGrowthEvent,
  trackKinesisEvent,
  trackMonetizationCtaEventKinesis,
  trackPartnerIntegrationEvent,
  trackSignUpEvent,
  trackUxEvent,
} from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

// Rails route :: React-Router route
const ROUTE_MAP = {
  '/employees': '/team',
  '/user/edit': '/settings/personal_info',
  '/reports': '/reports',
  '/hiring/dashboard': '/hiring/dashboard',
  '/billing': '/settings/billing',
};

const APP_CONTAINER_ID = 'js-react-app-container';
const APP_GLOBAL_COMPONENTS_CLASS = 'GlobalComponents';
const APP_ROOT_ID = 'react-app-root';
const APP_NAV_ROOT_ID = 'react-nav-root';
const APP_LEFT_NAV_ROOT_ID = 'react-left-nav-root-cell';

const END_OF_TRIAL_DIALOG_LIST_VERSION = 1;

const loadAssets = (assets = [], callback) => {
  const asset = assets.shift();

  if (!asset) {
    // We've loaded all of the assets and can actually render the page
    return callback();
  } else if (document.querySelectorAll(`script[src='${asset}']`).length) {
    // This asset has already been loaded, move on to the next one
    return loadAssets(assets, callback);
  }

  const script = document.createElement('script');
  script.setAttribute('src', asset);
  script.onload = () => loadAssets(assets, callback);

  document.head.appendChild(script);
};

const callBackboneMediator =
  method =>
  (...args) => {
    if (window.Backbone) {
      window.Backbone.Mediator[method](...args);
    }
  };

class RailsBridge {
  constructor(store, routesData, rootMounter, $) {
    this.store = store;
    this.routesData = routesData;
    this.rootMounter = rootMounter;
    this.$ = $;

    this.initBackboneSubscriptions();
    this.googleMaps = new GoogleMaps(
      sessionSelectors.getGoogleMapsKey(this.reduxState())
    );
  }

  // If you want to avoid hard-refresh of your new React page when you navigate to it
  // you should add that url to `session/index.json.rabl` to `reactRoutes` node
  navigateToReactView(route) {
    const reactRoute = ROUTE_MAP[route] || route;
    const routeData = this.routesData.find(data =>
      startsWith(route, data.route)
    );

    if (!routeData) {
      this.hardRefresh(route);
      return;
    }

    this.crossFade();

    loadAssets(routeData.assets || [], () => {
      this.removeSubMenu();

      // Mount react app if it's not currently mounted
      this.mountReactRootIfNecessary(reactRoute);

      this.handleFullScreenWorkflow(route);

      browserHistory.push(reactRoute);
    });
  }

  mountReactRootIfNecessary(reactRoute) {
    if (!document.getElementById(APP_ROOT_ID)) {
      // Remove any residue from react-modal used on Rails pages
      document.body.classList.remove('ReactModal__Body--open');

      const appContainer = document.getElementById(APP_CONTAINER_ID);

      // If no app container, the rails bridge doesn't know how to handle this view
      if (!appContainer) {
        window.location = reactRoute;
        throw new Error(
          `Unable to find node with classname: '${APP_CONTAINER_ID}'`
        );
      }

      appContainer.innerHTML = `<div id="${APP_ROOT_ID}"></div>`;
      this.rootMounter.mountApp();
    }
  }

  handleFullScreenWorkflow(route) {
    if (this.fullScreenEnabled) {
      this.toggleFullScreenElements(true);
      this.fullScreenEnabled = false;
    }

    if (FULL_SCREEN_PATHS.filter(r => route.includes(r)).length > 0) {
      this.fullScreenEnabled = true;
      this.toggleFullScreenElements(false);
    }
  }

  toggleFullScreenElements(show) {
    const display = show ? 'unset' : 'none';

    document.getElementById(APP_NAV_ROOT_ID).style.display = display;

    const leftNav = document.getElementById(APP_LEFT_NAV_ROOT_ID);
    if (leftNav) {
      if (show) {
        leftNav.classList.remove('hidden');
      } else {
        leftNav.classList.add('hidden');
      }
    }

    const globalComponents = document.getElementsByClassName(
      APP_GLOBAL_COMPONENTS_CLASS
    );

    for (let i = globalComponents.length - 1; i >= 0; --i) {
      globalComponents[i].style.display = display;
    }
  }

  crossFade() {
    const $rootNode = this.$('#App-react-component-0');
    $rootNode.fadeTo(0, 0, () => $rootNode.fadeTo(500, 1));
  }

  reduxState() {
    return this.store.getState();
  }

  dispatch(action) {
    this.store.dispatch(action);
  }

  showPartnerEndOfIntegrationModal(partnerName) {
    this.dispatch(modalsActions.showPartnerEndOfIntegrationModal(partnerName));
  }

  showEndOfTrialModal(
    trialManagers,
    secondLocationName,
    lastEndedTrialPeriodTierName
  ) {
    const onboardingTrialDaysLeft = sessionSelectors.getOnboardingTrialDaysLeft(
      this.reduxState()
    );

    const onboardingTrialSource = sessionSelectors.getOnboardingTrialSource(
      this.reduxState()
    );

    const endOfTrialDisplayVersion =
      sessionSelectors.selectEndOfTrialDisplayVersion(this.reduxState());

    const isEnrolledInStarterPackage =
      sessionSelectors.getStarterPackageEnabled(this.reduxState());

    const shouldShowEndOfTrialModal =
      !['direct_mail_free_trial', 'reactivation_free_trial'].includes(
        onboardingTrialSource
      ) && onboardingTrialDaysLeft < 1;

    if (shouldShowEndOfTrialModal && isEnrolledInStarterPackage) {
      this.dispatch(
        modalsActions.showEndOfTrialNbkDialog({
          trialManagers,
          secondLocationName,
        })
      );
    } else if (
      shouldShowEndOfTrialModal &&
      endOfTrialDisplayVersion === END_OF_TRIAL_DIALOG_LIST_VERSION
    ) {
      this.dispatch(
        modalsActions.showEndOfTrialListDialog({
          trialManagers,
          secondLocationName,
        })
      );
    } else if (shouldShowEndOfTrialModal) {
      this.dispatch(
        modalsActions.showEndOfTrialModal1(
          trialManagers,
          secondLocationName,
          lastEndedTrialPeriodTierName
        )
      );
    }
  }

  showManagerCapsBeforeEndOfTrialModal(trialManagers) {
    this.dispatch(
      modalsActions.showManagerCapsBeforeEndOfTrialModal(trialManagers)
    );
  }

  showEndOfTrialManagerModal() {
    this.dispatch(modalsActions.showEndOfTrialManagerModal());
  }

  showRetrialOnboardingModal(steps, startAtStep = 0) {
    this.dispatch(modalsActions.showRetrialOnboardingModal(steps, startAtStep));
  }

  showSecondLocationTrialUpgradeModal() {
    this.dispatch(modalsActions.showSecondLocationTrialUpgradeModal());
  }

  showEmptyScheduleDrawer(date, lastPublishedScheduleDate, force = false) {
    const params = {
      visible: true,
      date,
      lastPublishedScheduleDate,
    };

    if (force === true) {
      params.hasBeenClosed = false;
    }

    this.dispatch(toggleEmptyScheduleDrawer(params));
  }

  approveAvailabilityAlert(id) {
    this.dispatch(approveAvailabilityAlert(id));
  }

  showPrintDrawer(viewType, startDate, endDate) {
    trackUxEvent({
      productArea: PRODUCT_AREAS.SCHEDULE,
      eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER,
      eventAction: EVENT_ACTIONS.PRINT_DRAWER_SHOWN,
    });
    this.dispatch(togglePrintDrawer(true, viewType, startDate, endDate));
  }

  openMonetizationUpsellExportModal() {
    this.dispatch(openMonetizationUpsellExportModal());
  }

  toggleTemplatesDrawer(currentTemplateId) {
    this.dispatch(toggleTemplatesDrawer(undefined, currentTemplateId));
  }

  resolveClockOutApprovalAlert(shiftId) {
    this.dispatch(resolveClockOutApprovalAlert(shiftId));
  }

  templatesDrawerToggled(isShowing) {
    this.backbonePub('schedule:templates_drawer:toggled', isShowing);
  }

  showLocationChooserModal({ locations }) {
    this.dispatch(modalsActions.showLocationChooserModal(locations));
  }

  showSquareSuccessModal() {
    this.dispatch(modalsActions.showSquareSuccessModal());
  }

  showGustoSuccessModal() {
    this.dispatch(modalsActions.showGustoSuccessModal());
  }

  showAdpCreditTipsModal(callback) {
    const hasType = sessionSelectors
      .getCurrentCompany(this.reduxState())
      .getIn(['adp_properties', 'credit_tips_type']);

    if (hasType) {
      callback();
    } else {
      const props = { alreadyConnected: true, handleConfirm: callback };

      if (window.Popup.visible()) {
        window.Popup.close();
      }

      this.dispatch(settingsActions.showAdpCreditTipsSettingsModal(props));
    }
  }

  showAdpConnectModal(callback) {
    const props = {
      alreadyConnected: false,
      handleConfirm: callback,
      forceTipSettingSkip: true,
    };
    if (window.Popup.visible()) {
      window.Popup.close();
    }
    this.dispatch(settingsActions.showAdpCreditTipsSettingsModal(props));
  }

  showApplyChangesModal(callback) {
    this.dispatch(settingsActions.showApplyChangesModal(callback));
  }

  removeSubMenu() {
    this.$('.page-sub-menu').hide();
  }

  backbonePub = callBackboneMediator('pub');
  backboneSub = callBackboneMediator('sub');

  changeLocation(id, route) {
    window.Homebase.showPreloader();

    const promise = this.$.ajax({
      url: '/locations/select.json',
      type: 'PUT',
      data: { id },
      dataType: 'json',
    });

    promise.fail(() => {
      window.Homebase.hidePreloader();
      window.Homebase.showErrorMessage();
    });

    promise.success(() => this.hardRefresh(route));
  }

  pollWorkerStatus = pollWorkerStatus;

  pollBatchStatus = pollBatchStatus;

  removeSubHeader() {
    this.$('.page-sub-menu').remove();
  }

  hardRefresh(route) {
    window.Homebase.showPreloader();

    if (route) {
      window.location = route;
      return;
    }

    window.location = window.location.pathname;
  }

  backboneSubscribe(pubId, cb) {
    this.backboneSub(pubId, cb);
  }

  initBackboneSubscriptions() {
    this.backboneSubscribe('schedule:week_changed:after', (_, afterPublish) => {
      if (!afterPublish) {
        this.dispatch(togglePublishDrawer(false));
      }

      this.dispatch(toggleEmptyScheduleDrawer({ visible: false }));
    });
  }

  showHasAccessToManagerLog() {
    return sessionSelectors.getCurrentLocationManagerLogAccess(
      this.reduxState()
    );
  }

  // BILLING

  changeTier(backboneTier, billingCycle) {
    // in backbone, the "name" is apparently the button text, but for changeTier,
    // we need the name to actually be the name of the tier.
    const tier = fromJS(
      extend({}, backboneTier, {
        name: backboneTier.tier_name,
      })
    );
    this.dispatch(
      changeToNewTier({
        billingFrequency: billingCycle,
        tierName: tier.get('name'),
      })
    );
  }

  downgradeTier() {
    this.dispatch(changeToBasicTier());
  }

  changeTierToEssentials(billingCycle, workflowSource) {
    this.dispatch(
      changeToEssentialsTier({
        billingFrequency: billingCycle,
        workflowSource,
      })
    );
  }

  changeTierToPlus(billingCycle, workflowSource) {
    this.dispatch(
      changeToPlusTier({
        billingFrequency: billingCycle,
        workflowSource,
      })
    );
  }

  getShowGeofenceClockinLocationTooltip() {
    return sessionSelectors.getShowGeofenceClockinLocationTooltip(
      this.reduxState()
    );
  }

  getSingleTransactionBoostPrice(partner) {
    return hiringSelectors.getSingleTransactionBoostPrice(
      partner,
      this.reduxState()
    );
  }

  getSingleTransactionBoostDuration(partner) {
    return hiringSelectors.getSingleTransactionBoostDuration(
      partner,
      this.reduxState()
    );
  }

  paidPostingSuccess(id, postedAt) {
    this.dispatch(hiringActions.updateJobRequestPostedAt(id, postedAt));
  }

  createJobRequestBoost(jobRequestId, onSuccess, partnerName, claimedVia) {
    this.dispatch(
      hiringActions.createJobRequestBoost({
        jobRequestId,
        onSuccess,
        partnerName,
        claimedVia,
      })
    );
  }

  // MOUNT COMPONENTS
  mountReactMobileAppDownloadBanner() {
    this.rootMounter.mountMobileAppDownloadBanner();
  }

  mountReactHasNoManagerLogAccessView() {
    this.rootMounter.mountHasNoManagerLogAccessView();
  }

  mountFTUChecklist() {
    this.rootMounter.mountFTUChecklist();
  }

  trackMonetizationCtaEventKinesis = trackMonetizationCtaEventKinesis;

  trackKinesisEvent = trackKinesisEvent;

  trackUxEvent = trackUxEvent;

  trackSignUpEvent = trackSignUpEvent;

  trackGrowthEvent = trackGrowthEvent;

  getOriginContext = getOriginContext;

  setOriginContext = setOriginContext;

  trackTeamAvailabilityUxEvents = trackTeamAvailabilityUxEvents;

  trackPartnerIntegrationEvent = trackPartnerIntegrationEvent;

  trackPTORequestEvents = trackPTORequestEvents;

  facebookTrackEvent = facebookTrackEvent;

  trackGoogleConversionActivation = trackGoogleConversionActivation;

  trackBingActivation = trackBingActivation;

  showUpsellAndMetering() {
    return sessionSelectors.getShowUpsellAndMetering(this.reduxState());
  }

  fetchMeteringShiftNotesForDateCount() {
    this.dispatch(meteringActions.fetchMeteringShiftNotesForDateCount());
  }

  getMeteringLocationEventsForDateCount(year, month) {
    return meteringSelectors.getLocationEventsForDateCount(
      year,
      month,
      this.reduxState()
    );
  }

  fetchMeteringLocationEventsForDateCount() {
    this.dispatch(meteringActions.fetchMeteringLocationEventsForDateCount());
  }

  showPayBaseModal(type) {
    this.dispatch(modalsActions.showPayBaseModal(type));
  }

  showQ2TierChangeModal() {
    this.dispatch(showQ2TierChangeModal());
  }

  showTimesheetExportMonetizationUpsellModal() {
    return sessionSelectors.getShowTimesheetExportUpsellInterrupt(
      this.reduxState()
    );
  }

  showAdpInterruptModal() {
    this.dispatch(
      modalsActions.showADPRunInterruptModal(this.postDisableAdpInterruptModal)
    );
  }

  postDisableAdpInterruptModal(callback) {
    postJSON('/adp/interrupt/disable', {}).then(callback);
  }

  // MESSAGING
  openMessengerChannel(participantIds, params) {
    const { prefilledMessage } = params || {};

    const currentLocationId = sessionSelectors.getCurrentLocationId(
      this.reduxState()
    );

    this.dispatch(
      createChannel({
        location_id: currentLocationId,
        channel: {
          participants_attributes: participantIds.map(user_id => ({ user_id })),
        },
        prefilledMessage,
      })
    );
    this.dispatch(toggleMessengerShown(true));
  }

  prefillMessengerData(data) {
    const {
      user_ids: prefilledUserIds,
      channel_token: channelId,
      data: prefilledMessage,
    } = data;

    this.dispatch(
      setCurrentChatView({
        channelId,
        prefilledUserIds,
        prefilledMessage,
        view: VIEWS[channelId ? 'SHOW' : 'ADD'],
      })
    );
    this.dispatch(toggleMessengerShown(true));
  }

  getHasActiveTrialPeriod() {
    return sessionSelectors.getHasActiveTrialPeriod(this.reduxState());
  }

  getCurrentLocationActiveTrialEndDate() {
    return sessionSelectors.getCurrentLocationActiveTrialEndDate(
      this.reduxState()
    );
  }

  createMonetizationCtaEvent(eventType) {
    this.dispatch(createMonetizationCtaEvent(eventType));
  }

  getIsPayrollEnrolled() {
    return sessionSelectors.getIsPayrollEnrolled(this.reduxState());
  }

  showPayrollInProductLeadModal() {
    this.dispatch(modalsActions.showPayrollInProductLeadModal());
  }

  showAddExistingEmployeeInviteModal(attrs, numberOfRequests) {
    this.dispatch(
      modalsActions.showAddExistingEmployeeInviteModal(attrs, numberOfRequests)
    );
  }

  calendlyUrl() {
    const currentUser = sessionSelectors.getCurrentUser(this.reduxState());

    return buildTeamAppTeamCalendlyUrl(
      calendlyUrlBuilderCommand({ currentUser })
    );
  }

  //  COMPANY TIMESHEETS
  companyTimesheetsEnabled() {
    return sessionSelectors.getCompanyTimesheetsEnabled(this.reduxState());
  }

  companyTimesheetsAccessTier() {
    return sessionSelectors.getCompanyTimesheetsAccessTier(this.reduxState());
  }

  storeParams(queryParams) {
    return storeParams(queryParams);
  }

  isEmbedded() {
    return getIsEmbedded();
  }

  showEulaModal() {
    this.dispatch(modalsActions.showEulaModal());
  }

  printContent = printContent;
}

export default (...opts) => new RailsBridge(...opts);
