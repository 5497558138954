import { JobData, JobFormStage } from './types';

export const initialJobData: JobData = {
  job_title: '',
  job_description: '',
  prompt: '',
  job_requirements: {
    type: '',
    days: [],
    shifts: [],
    years_of_experience: '',
  },
  job_pay: {
    range: { min: 0, max: 0, frequency: 'hour' },
    tips: { amount: 0, time_range: '' },
    benefits: '',
  },
  more_info: '',
};

export const DEFAULT_JOB_ROLES = [
  'Server',
  'Cashier',
  'Bartender',
  'Barista',
  'Cook',
];

export const DAY_NUMBER_MAPPING: Record<string, string> = {
  0: 'monday',
  1: 'tuesday',
  2: 'wednesday',
  3: 'thursday',
  4: 'friday',
  5: 'saturday',
  6: 'sunday',
};

export const DAY_NUMBER_MAPPING_REVERSE: Record<string, string> = {
  monday: '0',
  tuesday: '1',
  wednesday: '2',
  thursday: '3',
  friday: '4',
  saturday: '5',
  sunday: '6',
};

export const STAGE_NUMBER_MAPPING: Record<JobFormStage, number> = {
  job_description: 1,
  screener: 2,
  review_and_post: 3,
};
